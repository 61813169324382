import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import mitt from "mitt";
import Wallet from "./wallet";
import Notifications from "@kyvg/vue3-notification";

const emitter = mitt();
const app = createApp(App);

app.use(router);
app.use(Notifications);
app.use(Wallet, {
  theme: "dark",
  infuraKey: import.meta.env.VITE_INFURA_KEY,
  chainIds: [import.meta.env.VITE_CHAINID],
  networkType: "testnet", //import.meta.env.VITE_ENV == "dev" ? "testnet" : "mainnet",
});
app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter);

app.mount("#app");
