<script setup lang="ts">
import type { PropType } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";

export type Dataset = {
  labels: Array<string>;
  datasets: Array<{
    label: string;
    backgroundColor: string;
    data: Array<number>;
  }>;
};

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

const props = defineProps({
  data: {
    type: Object as PropType<Dataset>,
    required: true,
  },
  styles: {
    type: Object,
  },
});

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
};
</script>

<template>
  <Line
    v-if="props.data"
    :chartData="props.data"
    :chartOptions="chartOptions"
    :styles="props.styles"
  />
</template>
