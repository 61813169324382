import { Vesting__factory } from "@/types/ethers";
import wp from "@/wallet";

export function getVestingContract() {
  if (!import.meta.env.VITE_VESTING) {
    throw new Error("No Vesting contract found");
  }
  const signerOrProvider = wp.useSignerOrProvider();
  return Vesting__factory.connect(
    import.meta.env.VITE_VESTING,
    signerOrProvider
  );
}
