import type { BigNumber } from "ethers";

export function formatDate(timestamp: BigNumber): string {
  const date = new Date(timestamp.toNumber() * 1000);
  const yyyy = date.getFullYear();
  let mm: number | string = date.getMonth() + 1; // Months start at 0!
  let dd: number | string = date.getDate();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd + "/" + mm + "/" + yyyy;
}

export function formatTime(timestamp: BigNumber): string {
  const date = new Date(timestamp.toNumber() * 1000);

  let hh: number | string = date.getHours();
  let mm: number | string = date.getMinutes();
  let ss: number | string = date.getSeconds();

  if (hh < 10) {
    hh = "0" + hh;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (ss < 10) {
    ss = "0" + ss;
  }
  return `${hh}:${mm}:${ss}`;
}

export function addDecimals(number: number, decimals = 2): string {
  return ((number * 100) / 100).toFixed(decimals);
}
